import { Partition } from '@snapchat/graphene';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'ar.snap.com',

  redirects: {
    hostRedirects: {},
    queryRedirects: {
      categories: 'select_categories',
      industry: 'select_industry',
      'trending-topics': 'select_trending-topics',
    },
  },
  globalNavProps: {
    defaultGroupKey: 'ar',
    backgroundColor: BackgroundColor.Black,
  },
  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.AR_SNAP },
      {
        vendor: LoggingVendor.GOOGLE,
        googleAnalyticsId: 'G-CYN4XLJBPJ',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'ar-snap-com',
        dsn: 'https://9dea64b91ff64ba8a1801fe1b9f5e478@sentry.sc-prod.net/134',
      },
    ],
  },
  persistentQueryParams: ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'],
  csp: {
    imgSrc: [
      'https://alb.reddit.com', // Allow reddit pixel tags
    ],
    connectSrc: [
      // https://switchboard.sc-corp.net/#/services/ar-web-backend -- APIs for ar.snap.com/download, owned by #lens-services-team-guest
      'https://ar-web-api.snapchat.com', // production API endpoint,
      'https://ar-web-api.snap-dev.net', // staging API endpoint
    ],
  },
};
