import { cx } from '@emotion/css';
import { CategoryOptInCookie } from '@snapchat/mw-cookie-components';
import { Alignment, Form } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext } from '../../../../AppContext';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import type { FormResponseWithUrl } from '../../../../components/Form';
import { UserAction } from '../../../../types/events';
import { makePostSubmitUrlWithDownloadId, persistDownloadId } from '../../helpers';
import type { PlatformString } from '../../server';
import { alignCenterCss, alignRightCss, formCss, hiddenCss } from './ArDownloadFormBlock.styles';
import { makeArDownloadFormFields } from './makeArDownloadFormFields';
import type { ArDownloadFormProps } from './types';
import { ArDownloadFormStyle } from './types';

export const ArDownloadForm: FC<ArDownloadFormProps> = ({
  id,
  endpoint,
  version,
  eula,
  genAiOptIn,
  platform,
  analytics,
  submitText,
  submitSuccessText,
  alignment = Alignment.Left,
  formStyle = ArDownloadFormStyle.Inline,
}) => {
  // use Memo is needed to prevent main Form component to rerenreder
  const ArFormFields = useMemo(
    () =>
      makeArDownloadFormFields({
        version,
        eula,
        genAiOptIn,
        platform,
        formStyle,
        alignment,
        submitText,
        submitSuccessText,
      }),
    [version, eula, genAiOptIn, platform, formStyle, alignment, submitText, submitSuccessText]
  );

  const [formDownloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const anchorTag = useRef<HTMLAnchorElement>(null);

  const { logEvent } = useContext(ConsumerContext);

  const { userLocation, currentLocale, cookieManager } = useContext(AppContext);
  const country = userLocation.country;

  useEffect(() => {
    if (formDownloadUrl && anchorTag.current) {
      anchorTag.current.click();
    }
  }, [formDownloadUrl]);

  const onSubmitSuccess = useCallback(
    async (response: Response): Promise<void> => {
      if (analytics && logEvent) {
        logEvent({ type: UserAction.FormSubmit, label: analytics.label ?? id });
      }

      try {
        const downloadFormResponse = (await response.json()) as FormResponseWithUrl & {
          version: string;
          platform: PlatformString;
          downloadId: string;
        };

        const { url, downloadId, platform, version } = downloadFormResponse;

        const acceptsMarketing =
          cookieManager.getCookie(CategoryOptInCookie.Essential) === 'true' &&
          cookieManager.getCookie(CategoryOptInCookie.Marketing) === 'true';

        if (acceptsMarketing) {
          persistDownloadId({ version, platform, downloadId }, cookieManager);
          const newurl = makePostSubmitUrlWithDownloadId({ version, platform, downloadId });
          window.history.pushState({ path: newurl }, '', newurl);

          if (analytics && logEvent) {
            logEvent({ type: UserAction.FormSubmit, label: 'lens_studio_post_submit' });
          }
        }

        setDownloadUrl(url);
      } catch (_err) {
        // reset to default
        setDownloadUrl(undefined);
      }
    },
    [analytics, logEvent, id, cookieManager]
  );

  const extraParams = useMemo(() => {
    return { locale: currentLocale, country };
  }, [currentLocale, country]);

  return (
    <>
      <Form
        className={cx(formCss, {
          [alignRightCss]: alignment === Alignment.Right,
          [alignCenterCss]: alignment === Alignment.Center,
        })}
        endpoint={endpoint}
        formChildrenRenderer={ArFormFields}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmitSuccess={onSubmitSuccess}
        extraParams={extraParams}
      />
      {/* DO NOT CHANGE THIS TO USE <Anchor/> THIS NEEDS TO STAY NATIVE <a/> so the lang query param does not get added */}
      <a
        data-testid="test-anchor-download"
        ref={anchorTag}
        href={formDownloadUrl}
        download
        className={hiddenCss}
      >
        Hidden download link
      </a>
    </>
  );
};
